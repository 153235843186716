import React from 'react'
import Layout from '../components/layout';
import Calculator from '../components/unit-calculator';


export default class IndexPage extends React.Component {

    render()
    {
        return (
            <Layout lang={'en'}
                    className={'nav--no_border'}
                    title={'Online conversion: cm, mm ↔︎ inch'}
                    description={'Online converter between Metric units and Imperial units: Centimetres (cm) and Millimetres (mm) to and from Inch.'}
                    translated={{de: '/de/einheiten-umrechnung/'}}
                    location={this.props.location.pathname}
            >
                <Calculator/>

                <div className="container mx-auto mt-12 text-center">
                    <h2 className={"text-2xl mb-4"}>Common sizes used for embroidery hoops:</h2>

                    <table className="table text-right table-striped mx-auto">
                        <thead>
                        <tr>
                            <th>Size in inch</th>
                            <th>Size in mm</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>4 x 4 inch</td>
                            <td>100 x 100 mm</td>
                        </tr>
                        <tr>
                            <td>5 x 7 inch</td>
                            <td>130 x 180 mm</td>
                        </tr>
                        <tr>
                            <td>6 x 10 inch</td>
                            <td>160 x 260 mm</td>
                        </tr>
                        <tr>
                            <td>8 x 10 inch</td>
                            <td>200 x 260 mm</td>
                        </tr>
                        <tr>
                            <td>8 x 8 inch</td>
                            <td>200 x 200 mm</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </Layout>
        )
            ;
    }
}
